import React from 'react';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import LinkIcon from '@mui/icons-material/Link';
import RedeemIcon from '@mui/icons-material/Redeem';
import { Box, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  format, intervalToDuration, isValid, parse,
} from 'date-fns';
import { AllowedYousignStatus } from '../../../../api';

function SectionTop({ queryInfo }: any) {
  function calculateFullAge(date: any) {
    const birthDate = parse(date, 'dd/MM/yyyy', new Date());
    const { years, months } = intervalToDuration({ start: birthDate, end: new Date() });
    return { years, months };
  }

  const parseName = (name: string) => {
    const defaultString = name;
    const stringTmp = defaultString.split('-');
    if (stringTmp[0] === 'DEFAULT' && stringTmp[1] === 'PROJECT') {
      return `✯ Nouveau-lead-${stringTmp[2]}`;
    }
    return '';
  };

  function chooseRank(birthdate: any) {
    const age = calculateFullAge(format(new Date(birthdate), 'dd/MM/yyyy')) || { years: 0, months: 0 };
    // @ts-ignore
    if (age?.months < 3) {
      return '🐣';
    }
    // @ts-ignore
    if (age?.months < 6) {
      return '🐥';
    }
    // @ts-ignore
    if (age?.months < 9) {
      return '🐱';
    }
    // @ts-ignore
    if (age?.years < 1) {
      return '🦁';
    }
    return '🐉';
  }

  return (
    <Box>
      <Box width="100%" display="flex" alignItems="center" paddingBottom={1}>
        <Box display="flex">
          {queryInfo?.data?.getProjectById?.hotDeal
            && <LocalFireDepartmentIcon fontSize="small" color="warning" sx={{ marginRight: 0.5 }} />}
          {queryInfo?.data?.getProjectById?.createdBy?.isCustomerAlready
            && (
              <LocalPoliceIcon fontSize="small" color="warning" sx={{ marginRight: 0.5 }} />
            )}
        </Box>
        <Tooltip
          title={queryInfo?.data?.getProjectById?.config.isDefaultProject ? parseName(queryInfo?.data?.getProjectById?.name) : queryInfo?.data?.getProjectById?.name}
          placement="bottom-start"
        >
          <Typography
            align="left"
            variant="h5"
            color="text.primary"
            fontWeight="bold"
            textOverflow="ellipsis"
            sx={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {queryInfo?.data?.getProjectById?.config.isDefaultProject ? parseName(queryInfo?.data?.getProjectById?.name) : queryInfo?.data?.getProjectById?.name}
          </Typography>
        </Tooltip>
      </Box>
      <Tooltip
        title={queryInfo?.data?.getProjectById?.createdBy?.email || ''}
      >
        <Typography
          align="left"
          variant="body2"
          fontWeight="400"
          color="primary"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <LinkIcon fontSize="small" color="primary" sx={{ marginRight: 1 }} />
          <Link
            to={`/clientcard?userId=${queryInfo?.data?.getProjectById?.createdBy?._id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            Lien vers fiche prospect
          </Link>
        </Typography>
      </Tooltip>
      {queryInfo?.data?.getProjectById?.createdBy?.referrerId
        && (
          <Box display="flex" marginTop={1} alignItems="center">
            <Tooltip
              title={queryInfo?.data?.getProjectById?.createdBy?.referrerId}
            >
              <Typography
                align="left"
                variant="body2"
                fontWeight="400"
                color="primary"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <RedeemIcon fontSize="small" color="primary" sx={{ marginRight: 1 }} />
                <Link
                  to={`/clientcard?userId=${queryInfo?.data?.getProjectById?.createdBy?.referrerId}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  Lien vers parrain
                </Link>
              </Typography>
            </Tooltip>
          </Box>
        )}
      {queryInfo?.data?.getProjectById?.createdAt && (
        <Typography
          component={Box}
          marginTop={1}
          align="left"
          variant="body2"
          color="text.secondary"
          display="flex"
          alignItems="center"
        >
          <Box
            component="p"
            fontSize={18}
            marginRight={0.5}
          >
            {chooseRank(isValid(new Date(queryInfo?.data?.getProjectById?.createdAt)) ? new Date(queryInfo?.data?.getProjectById?.createdAt) : new Date())}
          </Box>
          {`Crée le ${queryInfo?.data?.getProjectById?.createdAt && format(new Date(queryInfo?.data?.getProjectById?.createdAt), 'dd/MM/yyyy')}`}
        </Typography>
      )}
      {queryInfo?.data?.getProjectById?.config?.yousign_status === AllowedYousignStatus?.FINISHED
        && (
          <Typography
            component={Box}
            marginTop={1}
            align="left"
            variant="body2"
            color="text.secondary"
            display="flex"
            alignItems="center"
          >
            <Box
              component="p"
              fontSize={18}
              marginRight={0.5}
            >
              🟢
            </Box>
            {isValid(queryInfo?.data?.getProjectById?.config?.dateSignedContract) && `Mandat signé le ${queryInfo?.data?.getProjectById?.config?.dateSignedContract && format(new Date(queryInfo?.data?.getProjectById?.config?.dateSignedContract), 'dd/MM/yyyy')}`}
          </Typography>
        )}
      <Typography
        component={Box}
        marginTop={1}
        align="left"
        variant="body2"
        color="text.secondary"
        display="flex"
        alignItems="center"
      >
        <Box
          component="p"
          fontSize={18}
          marginRight={0.5}
        >
          {queryInfo?.data?.getProjectById?.createdBy?.typeformInfo?.investor?.toLowerCase() === 'oui' ? '🏛️' : '🏠'}
        </Box>
        {queryInfo?.data?.getProjectById?.createdBy?.typeformInfo?.investor?.toLowerCase() === 'oui' ? 'Investisseur confirmé' : 'Primo-investisseur'}
      </Typography>
      {queryInfo?.data?.getProjectById?.createdBy?.typeformInfo?.employment?.toLowerCase() === "chef d'entreprise ou indépendant" || queryInfo?.data?.getProjectById?.createdBy?.typeformInfo?.employment?.toLowerCase() === 'profession libérale'
        && (
          <Typography
            component={Box}
            marginTop={1}
            align="left"
            variant="body2"
            color="text.secondary"
            display="flex"
            alignItems="center"
          >
            <Box
              component="p"
              fontSize={18}
              marginRight={0.5}
            >
              💼
            </Box>
            {queryInfo?.data?.getProjectById?.createdBy?.typeformInfo?.employment}
          </Typography>
        )}
    </Box>
  );
}

export {
  SectionTop,
};
